.nav {
  .nav-subheader {
    display: flex;
    align-items: center;
    height: 48px;
    font-weight: 600;
    padding-left: 24px;
    margin-top: 8px;
    font-size: 12px;
    white-space: nowrap;
  }

  .nav-group {
    display: block;

    > .group-title {
      position: relative;
      display: flex;
      align-items: center;
      height: 48px;
      font-weight: 600;
      padding-left: 24px;
      margin-top: 8px;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  .nav-item {
    .nav-link {
      text-decoration: none !important;
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      color: currentColor;

      > .nav-link-title {
        flex: 1 1 auto;
        white-space: nowrap;
      }

      .nav-link-badge {
        display: flex;
        align-items: center;
        min-width: 28px;
        height: 28px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: 600;
        border-radius: 20px;
        transition: opacity 0.2s ease-in-out 0.1s;
        margin-left: 8px;

        + .collapsable-arrow {
          margin-left: 8px;
        }
      }

      &:hover {
        background-color: rgba(0, 145, 208, 0.1);
      }

      .mat-ripple-element {
        background-color: rgba(0, 145, 208, 0.1);
      }

      &.active {
        background-color: rgba(0, 145, 208, 0.1);

        .nav-link-icon {
          opacity: 1;
          color: rgb(0, 145, 208);

          font-size: 24px;
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          line-height: 24px;
        }

        .nav-link-title {
          font-weight: 500;
        }

        .nav-link-badge {
          background: #ffffff !important;
          // color: rgba(0, 0, 0, 0.87) !important;
        }
      }

      .nav-link-icon {
        margin-right: 16px;
      }

      .nav-link-icon,
      .collapsable-arrow {
        font-size: 20px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        line-height: 20px;
      }
    }

    &.nav-collapsable {
      display: block;

      > .children {
        > .nav-item {
          > .nav-link,
          &.nav-group > .group-title,
          &.nav-group > .group-items > .nav-item > .nav-link {
            padding-left: 56px;
          }

          > .children {
            > .nav-item {
              > .nav-link,
              &.nav-group > .group-title,
              &.nav-group > .group-items > .nav-item > .nav-link {
                padding-left: 72px;
              }
            }
          }
        }
      }
    }
  }

  > .nav-item {
    &.nav-collapsable {
      background: transparent;
      transition: background 200ms ease-in-out;

      &.open {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  > .nav-group {
    > .group-items {
      > .nav-collapsable {
        background: transparent;
        transition: background 200ms ease-in-out;

        &.open {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  &.vertical {
    .nav-group {
      .group-title {
        text-transform: uppercase;
      }
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;

    .nav-item {
      &.nav-collapsable {
        position: relative;

        .children {
          display: none;
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 999;
          min-width: 200px;
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);

          &.open {
            display: block;
          }

          .nav-link {
            padding-left: 24px !important;
          }
        }
      }
    }

    > .nav-item {
      > .nav-link {
        height: 56px;
      }

      &.nav-collapsable {
        position: relative;

        > .nav-link {
          height: 56px;

          .collapsable-arrow {
            display: none;
          }
        }

        > .children {
          top: 100%;
          left: 0;
        }
      }
    }
  }

  // Material 2 style
  &.material2,
  .material2 & {
    .nav-subheader {
      height: 40px;
    }

    .nav-group {
      > .group-title {
        height: 40px;
      }
    }
    .nav-item {
      .nav-link {
        height: 40px;
        padding: 0 12px 0 24px;
        border-radius: 0 20px 20px 0;
        margin-right: 16px;
      }
    }
  }
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}