// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";

// Plus imports for other components in your app.
// @import '~bootstrap/dist/css/bootstrap.min.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$cnc-accent: (
  50: #fdeae0,
  100: #fbcbb3,
  200: #f8a880,
  300: #f5854d,
  400: #f26a26,
  500: #f05000,
  600: #ee4900,
  700: #ec4000,
  800: #e93700,
  900: #e52700,
  A100: #ffffff,
  A200: #ffddd9,
  A400: #ffb0a6,
  A700: #ff998c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$cnc-primary: (
  50: #e0f2f9,
  100: #b3def1,
  200: #80c8e8,
  300: #4db2de,
  400: #26a2d7,
  500: #0091d0,
  600: #0089cb,
  700: #007ec4,
  800: #0074be,
  900: #0062b3,
  A100: #dcedff,
  A200: #a9d3ff,
  A400: #76b9ff,
  A700: #5dabff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cnc-primary: mat-palette($cnc-primary);
$cnc-accent: mat-palette($cnc-accent);

// The warn palette is optional (defaults to red).
$cnc-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$cnc-theme: mat-light-theme($cnc-primary, $cnc-accent, $cnc-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cnc-theme);

// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";
html,
body {
  height: 100%;
  letter-spacing: 0.2px !important;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.primary-fg {
  color: #0091d0 !important;
}
.accent-fg {
  color: #f05000 !important;
}

.gray-fg {
  color: #b9b9b9 !important;
}

.edit_hover_class .mat-icon {
  visibility: hidden;
}
.edit_hover_class:hover .mat-icon {
  color: #b9b9b9 !important;
  visibility: visible;
}

.edit_hover_class_dash .mat-icon.selector {
  visibility: hidden;
}
.edit_hover_class_dash:hover .mat-icon.selector {
  color: #b9b9b9 !important;
  visibility: visible;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px;
}

.mat-list-item:hover {
  background-color: rgba(0, 145, 208, 0.1);
}
.mat-list-item.no-hover:hover {
  background-color: rgba(255, 255, 255, 0);
}

.mat-menu-item:hover {
  background-color: rgba(0, 145, 208, 0.1) !important;
}

.mat-menu-item.selected {
  background-color: rgba(0, 145, 208, 0.1) !important;

  .mat-icon {
    color: #0091d0 !important;
  }
  span {
    font-weight: 900;
  }
}

.header-less-tabs.mat-tab-group .mat-tab-header {
  display: none;
}

.readOnly {
  span {
    color: rgba(0, 0, 0, 0.87);
  }
}

.mat-toolbar {
  background-color: white;
}

.container .table-container table {
  background-color: transparent;
}

.nonselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.sequence-of-checks-list {
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  // background: white;
  border-radius: 4px;
  // overflow: auto;
  margin: 12px 0;
}

.sequence-of-checks-list .sequence-of-checks-box:nth-child(1) {
  border-top-color: #007ec4;
}
.sequence-of-checks-list .sequence-of-checks-box:nth-child(2) {
  border-top-color: #0091d0;
}
.sequence-of-checks-list .sequence-of-checks-box:nth-child(3) {
  border-top-color: #26a2d7;
}
.sequence-of-checks-list .sequence-of-checks-box:nth-child(4) {
  border-top-color: #4db2de;
}
.sequence-of-checks-list .sequence-of-checks-box:nth-child(5) {
  border-top-color: #80c8e8;
}
.sequence-of-checks-list .sequence-of-checks-box:nth-child(6) {
  border-top-color: #b3def1;
}
.sequence-of-checks-list .sequence-of-checks-box:nth-child(7) {
  border-top-color: #dcecf3;
}

.sequence-of-checks-box {
  margin: 2px;
  padding: 15px 20px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: grab;
  background: white;
  font-size: 14px;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center !important;
  border-top-style: solid;
  border-radius: 6px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-top-width: 5px;
  max-width: 200px;
  min-height: 73px;

  &.selected {
    font-weight: 900;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-width: 1px;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0.2;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.sequence-of-checks-list.cdk-drop-list-dragging .sequence-of-checks-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
@import "app/core/styles/table.scss";

// -----------------------------------------------------------------------------------------------------
// NEWS
// -----------------------------------------------------------------------------------------------------
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-expansion-panel-header[aria-disabled="true"] {
  color: black;
  height: unset !important;
}

.under-review {
  background-color: #0091D0;
  border-radius: 12px;
  padding: 2px 8px;
  color: white;
  display: inline-block;
  text-align: center;
  font-size: 11px;
}