button,
input[type=email],
input[type=tel],
input[type=text],
input[type=password],
input[type=image],
input[type=submit],
input[type=button],
input[type=search],
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }
  
.mat-mdc-form-field-focus-overlay {
    background-color: white;
}
