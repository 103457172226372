.mat-column-no {
  text-align: right !important;
  max-width: 16px !important;
  padding-left: 0px !important;
  padding-right: 8px !important;
  border-right: 1px rgba(0, 0, 0, 0.12) solid !important;
}

.mat-paginator {
  background-color: rgba(255, 255, 255, 0);
}

.mat-row:hover {
  background-color: rgba(0, 145, 208, 0.1);
}
